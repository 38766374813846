import React from 'react';
import { set, get } from '../../../utils/cookies.js';

import {
  Container,
  Content,
  ContentLeft,
  LogoImage,
  LayerImage,
  StrongText,
  Button,
  CloseButton,
} from './styles.js';

class CyberWeekBRA2024 extends React.PureComponent {
  state = {
    visible: true,
    image: null,
    title: null,
    list: false,
    subtitle: null,
    validate: null,
    button: null,
    link: null,
    rules: null,
    load: null,
  };

  handleCloseButton = () => {
    this.setState({ visible: false });
    set('CyberWeekBRA2024', 'disabled', 4);
  };

  render() {
    const { visible } = this.state;

    return (
      visible &&
      !get('CyberWeekBRA2024') && (
        <Container>
          <Content>
            <ContentLeft>
              <LogoImage src="https://cdn.alboompro.com/5e1629425af36b0001effcfc_6751f88e078c2400018aae9b/original_size/group-66-1.svg?v=1" />
              <span><StrongText> ⏰ ÚLTIMO DIA:</StrongText> Assine ou renove a Alboom com até <StrongText>50% de desconto</StrongText></span>
            </ContentLeft>
            <Button
              href="https://lp.alboompro.com/cyber-week-2024?utm_campaign=cyber-week-2024-tarja-painel"
              target="_blank"
            >
            Aproveitar agora
            </Button>
          </Content>
          <CloseButton onClick={this.handleCloseButton}>&#10005;</CloseButton>
        </Container>
      )
    );
  }
}

export default CyberWeekBRA2024;