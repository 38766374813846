export const campaign_BFandCBW2024 = [
    15994,
    1854,
    273486,
    294261,
    38755,
    289348,
    279167,
    199703,
    5432,
    213332,
    115871,
    41681,
    223918,
    297291,
    113223,
    132028,
    168265,
    35571,
    231553,
    16135,
    120126,
    33322,
    15276,
    104982,
    2469,
    226844,
    126723,
    133850,
    116636,
    8754,
    34225,
    31084,
    276923,
    5933,
    14594,
    1110,
    141858,
    2860,
    5442,
    294068,
    13750,
    205385,
    198522,
    1224,
    4486,
    227295,
    222519,
    232817,
    292436,
    81602,
    296780,
    257884,
    38528,
    103441,
    292313,
    3723,
    5060,
    27784,
    39067,
    11376,
    294638,
    174675,
    5656,
    2073,
    48568,
    2922,
    93153,
    119850,
    1801,
    11946,
    290164,
    33974,
    107800,
    55722,
    6370,
    2183,
    173298,
    38749,
    6290,
    2455,
    11026,
    42949,
    7727,
    113474,
    5851,
    33287,
    3606,
    11467,
    2077,
    17770,
    105966,
    243109,
    1293,
    864,
    33098,
    7301,
    21921,
    1714,
    1337,
    3454,
    153074,
    52114,
    80540,
    26823,
    9708,
    161512,
    249795,
    44718,
    19607,
    65821,
    102447,
    884,
    117968,
    12441,
    4038,
    6088,
    1455,
    5636,
    3157,
    1854,
    224144,
    119584,
    3365,
    5606,
    4705,
    48836,
    5341,
    4022,
    200784,
    3453,
    1463,
    161625,
    3320,
    15600,
    238285,
    21831,
    210018,
    9905,
    61558,
    23987,
    10179,
    151573,
    5728,
    48603,
    7109,
    10441,
    275531,
    173516,
    6268,
    1517,
    32777,
    5427,
    15644,
    7189,
    16305,
    151556,
    2630,
    1983,
    6001,
    6319,
    46467,
    3319,
    1815,
    28999,
    14522,
    34078,
    10506,
    104989,
    1811,
    475,
    128368,
    150329,
    49102,
    4900,
    39133,
    19045,
    130909,
    2651,
    2897,
    966,
    176614,
    6336,
    13572,
    1653,
    19394,
    1863,
    6591,
    118110,
    3710,
    117468,
    44287,
    60182,
    54765,
    1541,
    45963,
    172185,
    84831,
    174929,
    14507,
    1160,
    224431,
    167056,
    155014,
    58602,
    173930,
    3469,
    298170,
    116992,
    55819,
    211625,
    754,
    435,
    27164,
    298931,
    48999,
    172055,
    6179,
    64393,
    49075,
    52116,
    3732,
    165926,
    4985,
    57382,
    4001,
    1397,
    3811,
    826,
    3176,
    3681,
    1995,
    290466,
    199345,
    251177,
    16418,
    14424,
    348,
    38597,
    39579,
    128450,
    11959,
    169560,
    243238,
    17814,
    18869,
    21432,
    196750,
    51569,
    2103,
    12914,
    56501,
    113638,
    5554,
    3139,
    5800,
    128420,
    58842,
    109075,
    1773,
    122619,
    97243,
    16304,
    141290,
    51597,
    106660,
    85983,
    127779,
    91014,
    172736,
    2497,
    172464,
    20045,
    1720,
    138632,
    1516,
    50061,
    224403,
    47446,
    34900,
    129006,
    8554,
    2319,
    155014,
    2457,
    220425,
    377,
    2226,
    8843,
    52457,
    159270,
    3998,
    16381,
    30972,
    33407,
    19457,
    4428,
    86142,
    14410,
    2628,
    47821,
    11836,
    5332,
    157800,
    224445,
    106333,
    5471,
    25564,
    131661,
    298666,
    1171,
    118799,
    141562,
    13894,
    227773,
    3240,
    6572,
    29763,
    182855,
    224447,
    27822,
    1495,
    63181,
    97895,
    102914,
    57223,
    44462,
    297095,
    51071,
    264888,
    40563,
    15994,
    618,
    4233,
    44518,
    91720,
    295443,
    5150,
    3363,
    1292,
    11281,
    2773,
    222756,
    227780,
    161705,
    3296,
    174093,
    171505,
    195314,
    175032,
    608,
    5658,
    2378,
    161450,
    4289,
    70409,
    5727,
    34109,
    11550,
    224429,
    52748,
    3533,
    218098,
    77118,
    29786,
    7211,
    163712,
    57496,
    1445,
    739,
    9231,
    15245,
    5437,
    21829,
    154027,
    160169,
    15118,
    60236,
    16857,
    108146,
    175864,
    5079,
    13358,
    227182,
    28792,
    42230,
    116053,
    6023,
    44759,
    214754,
    227605,
    4671,
    2683,
    128152,
    5673,
    2529,
    61391,
    15116,
    29782,
    224458,
    62092,
    210628,
    4186,
    42729,
    138470,
    3073,
    224458,
    6436,
    2937,
    23077,
    100330,
    52481,
    38995,
    7751,
    292576,
    228664,
    174625,
    191733,
    9889,
    173449,
    57078,
    3417,
    5338,
    5793,
    1251,
    15453,
    11043,
    173295,
    13279,
    75867,
    145100,
    1360,
    519,
    3575,
    578,
    2043,
    9765,
    6960,
    4044,
    15079,
    172530,
    27408,
    227629,
    27406,
    10459,
    36235,
    2162,
    159910,
    4051,
    3989,
    3029,
    174607,
    173091,
    9724,
    244910,
    5932,
    39908,
    195314,
    82837,
    48550,
    86640,
    30560,
    172649,
    3801,
    25774,
    121418,
    2752,
    4238,
    63184,
    4300,
    194959,
    151458,
    292221,
    223903,
    16402,
    15033,
    19179,
    6396,
    5166,
    33542,
    5313,
    166622,
    49671,
    121231,
    13260,
    85342,
    163578,
    158067,
    8752,
    218334,
    170906,
    299676,
    267298,
    4265,
    303697,
    44855,
    214871,
    240436,
    204914,
    186091,
    186224,
    304131,
    209496,
    223652,
    205703,
    274980,
    230360,
    304055,
    266216,
    277291,
    41132,
    291948,
    279100,
    225471,
    107076,
    66583,
    303775,
    2618,
    245443,
    234894,
    150608,
    303161,
    277741,
    251078,
    303412,
    293070,
    106279,
    288499,
    302406,
    302090,
    183713,
    302649,
    65747,
    27221,
    301441,
    3560,
    302321,
    302720,
    177431,
    79394,
    164278,
    302421,
    83112,
    230356,
    300235,
    121139,
    204970,
    111401,
    208377,
    51305,
    18168,
    277552,
    256783,
    301657,
    169693,
    224919,
    301554,
    256581,
    71966,
    301161,
    299166,
    272060,
    298958,
    293159,
    141029,
    156437,
    62215,
    1684,
    230863,
    234783,
    261042,
    217188,
    181331,
    300274,
    300660,
    200965,
    194829,
    300577,
    45037,
    115007,
    142192,
    284898,
    300582,
    298901,
    3897,
    13725,
    6716,
    1679,
    291754,
    171101,
    2624,
    2358,
    285623,
    211634,
    3507,
    237125,
    131437,
    16974,
    3487,
    136112,
    18451,
    13374,
    57515,
    36896,
    48651,
    4879,
    12050,
    1774,
    533,
    44458,
    43377,
    39358,
    41337,
    250684,
    141621,
    39215,
    3861,
    16504,
    13014,
    48419,
    257862,
    1144,
    61412,
    14265,
    212191,
    184006,
    234380,
    275855,
    135325,
    101935,
    58372,
    302851,
    104077,
    152916,
    2224,
    136395,
    1977,
    21792,
    12623,
    230312,
    41273,
    34057,
    191240,
    14599,
    134103,
    114532,
    176829,
    114802,
    39803,
    63354,
    35967,
    65646,
    72036,
    8981,
    30943,
    214637,
    67860,
    74115,
    45112,
    102060,
    44560,
    241066,
    50708,
    6968,
    13530,
    18128,
    3491,
    40620,
    41067,
    279295,
    202273,
    209452,
    50676,
    304215,
    302799,
    231237,
    1313,
    2758,
    94044,
    47467,
    16132,
    2664,
    110012,
    243540,
    202273,
    2603,
    151172,
    1132,
    187359,
    233346,
    15725,
    5180,
    12579,
    10084,
    7209,
    7482,
    233358,
    8517,
    175183,
    4569,
    222527,
    2908,
    116336,
    87452,
    158829,
    88471,
    987,
    39184,
    67667,
    40701,
    17278,
    140523,
    47216,
    407,
    41421,
    5833,
    172637,
    114547,
    1313,
    303877,
    47393,
    1563,
    829,
    4053,
    135162,
    7577,
    8994,
    304240,
    281749,
    3274,
    5164,
    1619,
    300034,
    1082,
    4265,
    40303,
    24504,
    1065,
    7090,
    21512,
    3830,
    54219,
    303218,
    23480,
    156653,
    119633,
    83206,
    4826,
    68076,
    266875,
    54469,
    107824,
    61760,
    50687,
    79004,
    2828,
    658,
    48024,
    2504,
    15530,
    75113,
    29567,
    3216,
    172947,
    124816,
    27282,
    16492,
    254703,
    26608,
    284187,
    136851,
    14327,
    119860,
    3145,
    4153,
    420,
    79990,
    243422,
    127868,
    15896,
    134683,
    110535,
    116809,
    4306,
    240758,
    292552,
    4698,
    3247,
    301690,
    300336,
    21708,
    137922,
    22721,
    29659,
    13359,
    113054,
    6631,
    241343,
    165770,
    208201,
    224719,
    85900,
    301071,
    300714,
    5235,
    296535,
    301124,
    2874,
    229861,
    16713,
    3027,
    44415,
    11131,
    173508,
    299829,
    47916,
    11048,
    4895,
    5132,
    87511,
    151566,
    230311,
    41348,
    2343,
    4587,
    3628,
    235685,
    1624,
    5196,
    9748,
    2439,
    11833,
    75457,
    53982,
    23791,
    5544,
    157652,
    115307,
    9770,
    166396,
    5058,
    294708,
    2725,
    300577,
    2782,
    4153,
    42410,
    2471,
    131120,
    81651,
    3403,
    79594,
    3145,
    3145,
    26829,
    226191,
    5267,
    998,
    180521,
    3866,
    114234,
    293153,
    2395,
    4957,
    80038,
    179007,
    230109,
    3457,
    302830,
    303375,
    298971,
    38028,
    130600,
    4839,
    230155,
    16444,
    94883,
    266729,
    264896,
    189467,
    303812,
    290954,
    121989,
    299104,
    303062,
    194884,
    294268,
    8404,
    226184,
    300219,
    52698,
    149631,
    252820,
    62136,
    32751,
    300760,
    44319,
    15674,
    299524,
    169441,
    1436,
    58805,
    230848,
    3116,
    3741,
    440,
    4177,
    3245,
    240758,
    5644,
    207,
    2635,
    15601,
    7352,
    21940,
    2681,
    2809,
    157894,
    300580,
    187285,
    3358,
    215027,
    277185,
    4334,
    66669,
    230524,
    18083,
    231006,
    22176,
    270258,
    230236,
    62378,
    44502,
    221474,
    894,
    40067,
    14768,
    4847,
    204874,
    2599,
    217646,
    2307,
    49984,
    4187,
    106065,
    74096,
    4953,
    301163,
    220606,
    4317,
    1891,
    255463,
    3279,
    9510,
    96115,
    5985,
    100338,
    4257,
    9033,
    9761,
    3903,
    50827,
    2384,
    94044,
    104537,
    104537,
    162007,
    5812,
    727,
    15223,
    300501,
    212928,
    208509,
    51601,
    134948,
    23598,
    696,
    37029,
    76941,
    11052,
    17582,
    187826,
    5243,
    284335,
    4795,
    32737,
    45697,
    186802,
    18945,
    118248,
    44851,
    8705,
    1842,
    88451,
    21495,
    132550,
    38937,
    10368,
    22250,
    4577,
    40523,
    267574,
    138745,
    73645,
    11021,
    159308,
    126215,
    11801,
    158771,
    174902,
    49983,
    33477,
    5508,
    11599,
    23249,
    68702,
    282188,
    107922,
    251299,
    273462,
    154601,
    158893,
    227644,
    52330,
    98205,
    3728,
    20780,
    2935,
    126754,
    159485,
    96442,
    47860,
    138274,
    3886,
    4497,
    285517,
    3191,
    129529,
    17527,
    2622,
    45584,
    48776,
    17904,
    2826,
    1487,
    5674,
    205073,
    231245,
    4893,
    220657,
    75292,
    1206,
    10927,
    256142,
    5916,
    10044,
    2721,
    12713,
    84493,
    8754,
    7352,
    7254,
    9713,
    102094,
    3004,
    8723,
    5221,
    2771,
    228255,
    78570,
    8852,
    20523,
    292658,
    86857,
    1766,
    21862,
    219358,
    224474,
    3864,
    132220,
    6620,
    131874,
    5105,
    16369,
    115879,
    225044,
    8625,
    11828,
    41272,
    43313,
    56650,
    5326,
    134442,
    41003,
    10643,
    167628,
    107529,
    2913,
    11574,
    3490,
    1179,
    25719,
    63678,
    25734,
    6183,
    18275,
    4256,
    43465,
    187792,
    144239,
    74898,
    5154,
    161499,
    70388,
    219502,
    271552,
    148706,
    253291,
    252255,
    119880,
    58777,
    57089,
    220695,
    182773,
    170814,
    56347,
    22200,
    122879,
    161792,
    79300,
    5511,
    25648,
    88102,
    23130,
    165696,
    152589,
    45424,
    160226,
    162051,
    279331,
    237580,
    263579,
    14262,
    86954,
    269971,
    163787,
    51900,
    1856,
    227739,
    1785,
    33301,
    192088,
    52711,
    96528,
    1944,
    5581,
    10875,
    78979,
    301879,
    86177,
    3120,
    14269,
    158673,
    49936,
    134787,
    121734,
    4231,
    9318,
    2721,
    23547,
    1526,
    2476,
    10154,
    12714,
    29594,
    38579,
    127431,
    157985,
    3707,
    78229,
    118609,
    51624,
    174451,
    2083,
    8508,
    2060,
    449,
    3047,
    3392,
    2769,
    2178,
    3147,
    229373,
    2561,
    46461,
    7257,
    383,
    241626,
    1633,
    1600,
    15613,
    12963,
    3138,
    2780,
    41142,
    2221,
    81511,
    9701,
    51246,
    126852,
    16507,
    2833,
    33745,
    8900,
    1612,
    5802,
    3686,
    67220,
    176487,
    1091,
    2921,
    33667,
    5907,
    1831,
    3616,
    490,
    107670,
    221223,
    2478,
    11690,
    4711,
    4915,
    74594,
    21376,
    4707,
    81596,
    230501,
    51683,
    6583,
    3520,
    3857,
    3781,
    23074,
    5829,
    1300,
    173512,
    5687,
    4495,
    15614,
    3248,
    175443,
    2583,
    1569,
    4993,
    141,
    108141,
    175417,
    159741,
    209593,
    224516,
    57532,
    96205,
    2606,
    4171,
    34319,
    2429,
    3683,
    235106,
    74011,
    216002,
    13407,
    24008,
    9036,
    1470,
    17830,
    920,
    3054,
    5539,
    302092,
    175404,
    300572,
    54212,
    230541,
    3847,
    42856,
    3955,
    5182,
    14042,
    2040,
    5936,
    8894,
    28683,
    81490,
    229061,
    11072,
    38271,
    1418,
    41109,
    10354,
    2766,
    15678,
    65487,
    3857,
    7026,
    284873,
    4572,
    15921,
    61038,
    2899,
    4375,
    16894,
    29774,
    5178,
    4390,
    12161,
    202841,
    52135,
    128443,
    1449,
    3254,
    116620,
    2210,
    7286,
    6277,
    1827,
    229921,
    3480,
    3820,
    51293,
    4551,
    129099,
    32279,
    103239,
    88502,
    6896,
    161090,
    15166,
    1902,
    385,
    203186,
    21533,
    150630,
    29336,
    1371,
    304059,
    3579,
    5486,
    3489,
    133891,
    300957,
    11611,
    46995,
    66223,
    304312,
    41611,
    48044,
    4710,
    174675,
    22143,
    173934,
    37501,
    131003,
    188687,
    12023,
    729,
    303806,
    22670,
    4125,
    171280,
    86599,
    89658,
    2794,
    72650,
    153905,
    85311,
    3378,
    130555,
    179007,
    194959,
    106345,
    55129,
    668,
    125029,
    1801,
    500,
    224464,
    1821,
    7071,
    5261,
    163578,
    19165,
    172065,
    1432,
    65802,
    1449,
    37252,
    12286,
    24843,
    6396,
    9724,
    10179,
    55978,
    7155,
    173516,
    6268,
    1517,
    63184,
    5427,
    15644,
    7189,
    16305,
    151556,
    2630,
    19879,
    754,
    738,
    7174,
    109194,
    5554,
    144,
    16410,
    40447,
    172965,
    254508,
    20022,
    141290,
    51597,
    6293,
    3770,
    170622,
    195477,
    8989,
    2380,
    2497,
    129732,
    3915,
    10542,
    167253,
    3500,
    1120,
    80383,
    178259,
    13894,
    102914,
    1081,
    25556,
    1292,
    11281,
    2773,
    222756,
    414,
    2757,
    4850,
    42230,
    116053,
    6023,
    3286,
    2824,
    25774,
    9471,
    6608,
    1220,
    110893,
    1148,
    85342,
]