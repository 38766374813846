import React from 'react'
import { set, get } from '../../../utils/cookies.js';

import { Container, LogoImage, StrongText, Content, ContentLeft, Button, CloseButton, Stripe } from './styles'

class BlackFridayBR24 extends React.PureComponent {
  state = {
  visible: true,
  image: null,
  title: null,
  list: false,
  subtitle: null,
  validate: null,
  button: null,
  link: null,
  rules: null,
  load: null
  }

  handleCloseButton = () => {
    this.setState({ visible: false })
    set('BlackFridayBR24', 'disabled', 4)
  }

  render() {
    const { visible } = this.state

    return (
      visible &&
      !get('BlackFridayBR24') && (
        <Stripe>
          <Container>
            <Content>
              <ContentLeft>
                <LogoImage src="https://cdn.alboompro.com/5e1629425af36b0001effcfc_65565663b2a9c800014e0343/original_size/logo-black.gif?v=1" />
                <span> ⏰ Termina hoje! <StrongText> Até 60%OFF + R$900 em bônus!</StrongText></span>
              </ContentLeft>
              <Button
                href="https://lp.alboompro.com/black-friday-2024?utm_campaign=black-friday-2024-tarja-painel"
                target="_blank">
                Saiba mais
              </Button>
            </Content>
            <CloseButton onClick={this.handleCloseButton}>&#10005;</CloseButton>
          </Container>
        </Stripe>
      )
    )
  }
}

export default BlackFridayBR24
