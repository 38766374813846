import styled from 'styled-components'

export const Stripe = styled.div``

export const Container = styled.div`
  display: flex;
  justify-content: space-around;
  background: #000000;
  position: relative;
  height: auto;
  border-radius: 2px;
  padding: 0px 40px;

  @media screen and (max-width: 960px) {
    flex-direction: column;
    padding: 0px;
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  padding: 12px;
  justify-content: center;
  align-items: center;
  max-width: 1170px;
  width: 100%;
  
  @media screen and (max-width: 960px) {
    flex-direction: column;
    align-items: center;
  }
`

export const ContentLeft = styled.div`
  color: #ffffff;
  text-align: left;
  align-items: center;
  font-weight: 400;
  width: 76%;
  display: flex;
  justify-content: space-around;

  @media screen and (max-width: 768px) {
    max-width: 100%;
    width: 100%;
    font-size: 13px;
    align-items: center;
    display: flex;
    flex-direction: column;
  }

  span { 
    font-size: 24px;
    @media screen and (max-width: 768px) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
`


export const Button = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  background-color: #54BBAB;
  font-size: 16px;
  border: solid 1px rgb(255,0,138,1);
  filter: drop-shadow(3px 3px 0px #FF298A);
  text-align: center;
  color: #462F80;
  font-size: 20px;
  font-weight: 700;
  padding: 10px 20px;
  width: 200px;
  height: 40px;

  &:hover {
    background-color: rgb(255,0,138,1);
    filter: drop-shadow(3px 3px 0px #54BBAB);
    color: #ffffff;
  }

  @media screen and (max-width: 768px) {
    height: auto;
    margin-bottom: 30px;
  }
`

export const CloseButton = styled.span`
  font-size: 16px;
  position: absolute;
  top: 18px;
  right: 18px;
  color: #ffffff;
  cursor: pointer;
  z-index: 3;

  @media (max-width: 600px) {
    font-size: 23px;
    top: 5px;
    right: 5px;
  }
`

export const LogoImage = styled.img`
  width: 250px;
  height: 20px;

  @media screen and (max-width: 768px) {
    margin: 20px 0 6px 0;
  }

`

export const LayerImage = styled.img`
  width: 49px;
  height: 27px;
  margin: 0 20px;

  @media screen and (max-width: 768px) {
    display: none;
  }
`

export const StrongText = styled.strong`
  color: #FFF;
  font-size: 24px;
  font-weight: 700;

@media screen and (max-width: 768px) {
  display: flex;
  text-align: center;
  margin: 16px 0;
  font-size: 32px;
}
`